<template>
  <main class="grid">
    <div id="add-genres">
      <h1>Add New Genres</h1>

      <input
        v-model="text"
        list="genres"
        type="text"
        placeholder="Enter a new genre"
        @keypress.enter="saveGenre"
        :disabled="savingGenre"
        required
      />
      <datalist id="genres">
        <option
          v-for="genre in genreOptions"
          :key="genre.id"
          :value="genre.name"
        >
          {{ genre.name }}
        </option>
      </datalist>

      <div class="profile-title">
        <span> Genres </span>
      </div>

      <div class="genres-grid" :class="{ small: settings.genres.length > 10 }">
        <div
          v-for="genre in settings.genres"
          :key="genre"
          class="genre"
          title="Click to delete"
          @click="removeGenre(genre)"
        >
          {{ genre }}
        </div>
      </div>
    </div>
    <form id="limits" @submit.prevent="updateLimits">
      <h1>System Preferences</h1>
      <fieldset :disabled="savingLimits">
        <input
          v-model="pdfPageLimit"
          type="number"
          placeholder="Set a new limit for the maximum number of pages uploadable as a pdf"
          min="1"
          required
        />
        <input
          v-model="playsLimit"
          type="number"
          placeholder="Set a new limit for the maximum number of plays fetched in the homepage"
          min="20"
          required
        />

        <button class="btn" type="submit" :disabled="!canUpdateLimits">
          Update Limits
        </button>
      </fieldset>
    </form>
    <div id="play-of-the-month">
      <h1>Play of the Month</h1>
      <select name="" id="" @change="savePlayOfTheMonth($event.target.value)">
        <option value="">Choose a Play</option>
        <option v-for="play in plays" :key="play.id" :value="play.id">
          {{ play.name }}
        </option>
      </select>

      <div class="profile-title">
        <span> Current Play of the Month </span>
      </div>

      <div class="play-of-the-month">
        <div class="genre">{{ playOfTheMonth.name }}</div>
      </div>
    </div>
  </main>
</template>

<script>
import { preferencesCollections, firebase } from "@/firebase-config";
import { mapGetters } from "vuex";
import genreOptions from "@/data/genres.json";
export default {
  name: "SiteOptions",
  data() {
    return {
      text: "",
      pdfPageLimit: 0,
      playsLimit: 0,
      savingGenre: false,
      fetchingPreferences: false,
      savingLimits: false,
    };
  },
  watch: {
    settings(newValue) {
      this.pdfPageLimit = newValue.pdfPageLimit;
      this.playsLimit = newValue.playsLimit;
    },
  },
  computed: {
    genreOptions() {
      return genreOptions.filter((x) => !this.settings.genres.includes(x.name));
    },
    ...mapGetters(["settings", "plays"]),
    canUpdateLimits() {
      return (
        this.pdfPageLimit !== this.settings.pdfPageLimit ||
        this.playsLimit !== this.settings.playsLimit
      );
    },
    playOfTheMonth() {
      return this.plays.find((play) => play.id === this.settings.promoted);
    },
  },
  methods: {
    async saveGenre() {
      if (!this.text) return;
      this.savingGenre = true;
      try {
        await preferencesCollections.doc("zSGrJDCeQrmF4HYFWOao").update({
          genres: firebase.firestore.FieldValue.arrayUnion(this.text),
        });
        this.text = "";
      } catch (err) {
        throw new Error(err);
      } finally {
        this.savingGenre = false;
      }
    },
    async updateLimits() {
      if (!this.canUpdateLimits) return;
      this.savingLimits = true;
      try {
        await preferencesCollections.doc("zSGrJDCeQrmF4HYFWOao").update({
          pdfPageLimit: this.pdfPageLimit,
          playsLimit: this.playsLimit,
        });
        this.text = "";
      } catch (err) {
        throw new Error(err);
      } finally {
        this.savingLimits = false;
      }
    },
    async removeGenre(text) {
      try {
        await preferencesCollections.doc("zSGrJDCeQrmF4HYFWOao").update({
          genres: firebase.firestore.FieldValue.arrayRemove(text),
        });
      } catch (err) {
        throw new Error(err);
      }
    },
    async savePlayOfTheMonth(id) {
      if (!id) return;
      try {
        await preferencesCollections.doc("zSGrJDCeQrmF4HYFWOao").update({
          promoted: id,
        });
      } catch (err) {
        throw new Error(err);
      }
    },
  },
  mounted() {
    this.pdfPageLimit = this.settings.pdfPageLimit;
    this.playsLimit = this.settings.playsLimit;
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
}

#add-genres,
#limits,
#play-of-the-month {
  order: 2;
  background: #202125;
  width: 100%;
  padding: 30px;
  border-radius: 10px;

  input {
    height: 50px;
    background: black;
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    padding: 0 15px;
    color: $light-grey;

    &:not(:valid) {
      border-bottom: 3px solid $dark-red;
    }
  }

  .genres-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    @media (min-width: 764px) {
      grid-template-columns: 1fr;

      &.small {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      }
    }
  }
  .genre {
    padding: 20px;
    background: black;
    transition: 0.2s all ease;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background: $dark-red;
    }
  }
}

#limits {
  background: darken($color: darkslategrey, $amount: 19);
  order: 1;

  fieldset {
    border: none;
  }

  button.btn {
    background: $light-grey;
    color: black;
    padding: 15px;
    display: block;
    width: 100%;
    border-radius: 100px;
    margin-top: 20px;
    transition: 0.2s all ease;

    &:not(:disabled):hover {
      background: $dark-grey;
    }

    &:disabled {
      cursor: not-allowed;
      background: darkgrey;
    }
  }
}

#play-of-the-month {
  select {
    height: 50px;
    background: black;
    margin-top: 20px;
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 0 15px;
    color: $light-grey;

    &:not(:valid) {
      border-bottom: 3px solid $dark-red;
    }
  }
}

@media (min-width: 764px) {
  #add-genres {
    order: 1;
  }

  #limits {
    order: 2;
  }
}
</style>
